import React, {useState, useCallback, MouseEvent} from 'react';
import logo from './logo.svg';
import background from './assets/svg/background.svg';
import refresh from './assets/svg/refresh.svg';
import './App.scss';
import { Station } from './components/Station';

function App() {
  const data = {};
  const axios = require('axios').default;
  const [stationList, setStationList] = useState();
  const[reloading, setReloadStatus] = useState(false);
  
  var favs:string[] = new Array(
    "Akrafjall",
    "Blikdalsá",
    "Kjalarnes",
    "Straumsvík",
    "Reykjanesbraut"
  );

  React.useEffect(() => {
    axios.get('https://belgurinn.is/api/vedur/vedur.json')
      .then((response: any) => {
          setStationList(response.data);
        })
        .catch((error: any) => {
          console.error('Error', error);
        })
        .then(() => {
          // always executed
        });

  }, [reloading]);

  function refreshData() {
    setReloadStatus(true);
    axios.get('https://belgurinn.is/api/vedur/vedur.json')
      .then((response: any) => {
        setTimeout(() => {
          setReloadStatus(false);
        }, 1500)
        setStationList(response.data);
      })
      .catch((error: any) => {
        console.error('Error', error);
      })
      .then(() => {
        // always executed
      });
  }

  return (
    <div className="wind">
      <div className='wind__back'>
        <img src={background} className="wind__back__img" alt="VindBelgurinn" />
      </div>
      <header className="wind-header">
        <img src={logo} className="wind-header__logo" alt="VindBelgurinn" />
      </header>
      <div className='wind-content'>
        {stationList && favs.map((station: any, index: any) => {

          return (
            <Station key={index} station={stationList[station]} loading={reloading} />
          )
        })}
      </div>
      <button onClick={(event) => {
          refreshData();
        }} className="wind-reload"><img src={refresh} alt="" /></button>
    </div>
  );
}

export default App;

