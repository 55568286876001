import React from 'react';
import './station.scss';

type StationProps = {
    station: any;
    loading: Boolean;
}

const roundNumber = (nr: any) => {
    return Math.round(nr);
}

export const Station = (props: StationProps) => {

    const station = props.station;

    return (
        <section className={`station ${roundNumber(station.Vindhvida) >= 15 ? 'alert' : ''} ${props.loading ? 'loading-animation' : ''}`}>
            <header className='station__header'>
                <h1>{ station.Nafn }</h1>
                <span>{props.loading ? 'Sæki gögn' : ''}</span>
            </header>
            <div className='station__info'>
                <div className='station__info__data'>
                    <h2>Vindátt</h2>
                    <div>{ station.VindattAsc }</div>
                </div>
                <div className='station__info__data'>
                    <h2>Vindhraði</h2>
                    <div>{ roundNumber(station.Vindhradi) } <sup>m/s</sup></div>
                </div>
                <div className={`station__info__data ${roundNumber(station.Vindhvida) >= 15 && 'alert'}`}>
                    <h2>Vindhviður</h2>
                    <div>{ roundNumber(station.Vindhvida) } <sup>m/s</sup></div>
                </div>
                <div className='station__info__data'>
                    <h2>Hitastig</h2>
                    <div>{ roundNumber(station.Hiti) } <sup>°C</sup></div>
                </div>
                <div className='station__info__data'>
                    <h2>Umferð 10 mín</h2>
                    <div>{ station.Umf10Min }</div>
                </div>
                <div className='station__info__data'>
                    <h2>Umferð 24 klst</h2>
                    <div>{ station.UmfSum }</div>
                </div>
            </div>
            <footer className='station__footer'>
                Upplýsingar frá Vegagerðinni { station.Dags } 
            </footer>
        </section>
    )
}